// @flow strict
import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEnvelope,
  faMapMarkedAlt,
  faPhone,
} from '@fortawesome/free-solid-svg-icons'

import Layout from '../components/layout'
import Paragraph from '../components/paragraph'
import Column from '../components/column'
import Map from '../components/map'

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 700px) {
    width: 30%;
  }
`

const Input = styled.input`
  margin: 0.5rem 0;
  width: ${({ width = 'auto' }) => width};
  color: black;
  padding: 0.5rem;
  background-color: ${({ background = 'white' }) => background};
`

const InputButton = styled(Input)`
  &:hover {
    background-color: black;
    color: white;
  }
`

const Icon = styled(FontAwesomeIcon)`
  margin-bottom: 2rem;
`

type Props = {
  data: {
    site: {
      siteMetadata: {
        email: string,
      },
    },
  },
  location: {
    pathname: string,
  },
}

const Contact = (props: Props) => {
  const {
    data: {
      site: {
        siteMetadata: { email },
      },
    },
  } = props
  return (
    <Layout {...props}>
      <Column>
        <h1>Contact Us</h1>
        <FontAwesomeIcon icon={faEnvelope} size="2x" />
        <Form action={`https://formspree.io/${email}`} method="POST">
          <Paragraph margin="2rem 0">
            If you would like to get in touch please enter your name and email
            below. We will respond to you as soon as we can.
          </Paragraph>
          <Input width="100%" placeholder="name" type="text" name="name" />
          <Input
            width="100%"
            placeholder="email"
            type="email"
            name="_replyto"
          />
          <Input
            width="100%"
            as="textarea"
            placeholder="Your message"
            name="message"
          />
          <InputButton width="50%" type="submit" value="Send" />
        </Form>
      </Column>
      <Column>
        <Icon icon={faMapMarkedAlt} size="2x" />
        <Paragraph textAlign="center" height="6rem">
          209, Igbosere Road Lagos,
          <br />
          P.O. Box 72636, Victoria Island
          <br />
          Lagos, Nigeria
        </Paragraph>
        <Map />
      </Column>
      <Column margin="1rem">
        <Icon icon={faPhone} size="2x" />
        <Paragraph height="6rem">
          <span>Phone (1): 0809 657 6690</span>
          <br />
          <span>Phone (2): 0909 555 5520</span>
        </Paragraph>
      </Column>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        email
      }
    }
  }
`

export default Contact

import styled from 'styled-components'

const Column = styled.div`
  display: flex;
  flex-direction: column;
  height: ${({ height = 'auto' }) => height};
  width: ${({ width = 'auto' }) => width};
  align-items: center;
  justify-content: center;
  margin: ${({ margin = 'auto' }) => margin};
  ${({ padding }) => padding && `padding: ${padding}`};
`

export default Column

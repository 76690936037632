import styled from 'styled-components'
import React from 'react'
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps'

const coords = {
  lat: 6.4519442,
  lng: 3.3948197,
}
const MapComponent = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap defaultZoom={15} defaultCenter={coords}>
      {props.isMarkerShown && <Marker position={coords} />}
    </GoogleMap>
  ))
)

const googleMapsUrl = `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${
  process.env.GATSBY_MAPS_API_KEY
}&libraries=geometry,drawing,places`

const MapContainer = styled.div`
  height: 20%;
  width: 100%;
`

const Map = () => (
  <MapComponent
    isMarkerShown
    googleMapURL={googleMapsUrl}
    loadingElement={<div style={{ height: `100%` }} />}
    containerElement={<MapContainer />}
    mapElement={<div style={{ height: `400px` }} />}
  />
)

export default Map
